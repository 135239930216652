export type CUSTOM_BANNERS_GET_REQUEST = 'CUSTOM_BANNERS_GET_REQUEST';
export const CUSTOM_BANNERS_GET_REQUEST = 'CUSTOM_BANNERS_GET_REQUEST';
export type CUSTOM_BANNERS_GET_SUCCESS = 'CUSTOM_BANNERS_GET_SUCCESS';
export const CUSTOM_BANNERS_GET_SUCCESS = 'CUSTOM_BANNERS_GET_SUCCESS';
export type CUSTOM_BANNERS_GET_FAILED = 'CUSTOM_BANNERS_GET_FAILED';
export const CUSTOM_BANNERS_GET_FAILED = 'CUSTOM_BANNERS_GET_FAILED';

export const CUSTOM_BANNERS_LOCATION_PRODUCT_LIST = 'product_list';
export const CUSTOM_BANNERS_LOCATION_PRODUCT_DETAIL = 'product_detail';
export type CUSTOM_BANNERS_LOCATION_PRODUCT_LIST = 'product_list';
export type CUSTOM_BANNERS_LOCATION_PRODUCT_DETAIL = 'product_detail';

export const CUSTOM_BANNERS_TYPE_BANNER = 'banner';
export const CUSTOM_BANNERS_TYPE_NOTIFICATION = 'notification';
export type CUSTOM_BANNERS_TYPE_BANNER = 'banner';
export type CUSTOM_BANNERS_TYPE_NOTIFICATION = 'notification';
export const CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN = 'top_campaign';
export type CUSTOM_BANNERS_TYPE_TOP_CAMPAIGN = 'top_campaign';
