import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { mobileMode } from '@constants/config';
import { appNavigationHeightSm, maxWidth } from '@constants/styles';

export default makeStyles<Theme, {}>((theme) =>
    createStyles({
      pageSize: {
        [theme.breakpoints.up('md')]: {
          paddingLeft: '80px',
          paddingRight: '80px',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          paddingLeft: '30px',
          paddingRight: '30px',
        },
        [theme.breakpoints.down('md')]: {
          paddingLeft: '0',
          paddingRight: '0',
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth,
        paddingBottom: mobileMode ? appNavigationHeightSm : 0,
      },
      pageSizeMobile: {
        '@supports (padding-top: constant(safe-area-inset-bottom))': {
          paddingBottom: `calc(${appNavigationHeightSm}px + constant(safe-area-inset-bottom) * 2)`,
        },
        '@supports (padding-top: env(safe-area-inset-bottom))': {
          paddingBottom: `calc(${appNavigationHeightSm}px + env(safe-area-inset-bottom) * 2)`,
        },
      },
    }),
);
