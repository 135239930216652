import { acGetBrands } from '@actions/acBrands';
import {
  mapFilterToUrl, mapSortToApiRequest, setPredefinedFilter,
} from '@components/pages/productsList/utils';
import * as actions from '@constants/actions/productsList';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { EGender } from '@constants/gender';
import { itemsPerPage } from '@constants/index';
import { ELanguages } from '@constants/languages';
import { PageTypes } from '@constants/pageTypes';
import { defaultSorting, ESortType } from '@constants/sort';
import { RequestMethods } from '@constants/types';
import {
  getProductFilterUrl,
  getProductFilterUrlLocal,
  getProductPerformanceUrl,
  getProductsListUrl,
} from '@constants/urls';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { IContextQuery } from '@interfaces/context/reducer';
import { IAuthParams, ILocaleParamsShort } from '@interfaces/index';
import { ISeo } from '@interfaces/pageData/reducer';
import {
  EProductsListFilter, EProductsListSort, IProductListFilterBrand,
} from '@interfaces/productsList';
import {
  IProductsListFilterCancel, IProductsListFilterChange, IProductsListFilterClear,
  IProductsListFilterMobileChange, IProductsListFilterMobileClear, IProductsListFinishInit,
  IProductsListMobileFilterApply, IProductsListSortChange,
} from '@interfaces/productsList/actions';
import { IProductListFilter } from '@interfaces/productsList/reducer';
import { IStore } from '@interfaces/store';
import { getPageSexId, mapFilterData } from '@reducers/productsList/utils';

import { CALL_API } from '../../services/SSRService';
import {
  addParamsToUrl, findBrandBySlug, findCategoryBySlug,
  getBasePathAndSexSlug,
} from '../../services/SSRService/utils';

export interface IGetProductsParams extends ILocaleParamsShort, IAuthParams, Partial<IProductListFilter> {
  includeSizes?: boolean;
  page: number;
  size?: number;
  params?: any;
  ga?: string | null;
}

type TProductsListGet = (params: Partial<IGetProductsParams>, short?: boolean) => any;
export const acProductsListGet: TProductsListGet = (params, short = false) => ({
  [CALL_API]: {
    cache: true,
    cacheOptions: ERedisCacheKey.default,
    endpoint: addParamsToUrl(short ? getProductPerformanceUrl() : getProductsListUrl(), {
      ...params,
      includeCounts: true,
      includeSizes: true,
      size: params.size || itemsPerPage,
    }),
    method: RequestMethods.GET_METHOD,
    types:
      params && params.page && params.page > 0
        ? [
          actions.PRODUCTS_LIST_GET_NEXT_PAGE_REQUEST,
          actions.PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS,
          actions.PRODUCTS_LIST_GET_NEXT_PAGE_FAILED,
        ]
        : (
          short ? [actions.PRODUCTS_LIST_SHORT_GET_REQUEST, actions.PRODUCTS_LIST_SHORT_GET_SUCCESS, actions.PRODUCTS_LIST_SHORT_GET_FAILED]
            : [actions.PRODUCTS_LIST_GET_REQUEST, actions.PRODUCTS_LIST_GET_SUCCESS, actions.PRODUCTS_LIST_GET_FAILED]),
  },
});

export const acProductsListGetAction = (params, short = false) => (dispatch, getState: () => IStore) => {
  const {
    pageData,
    productsList: { filter, dataFilter, sortDirection },
  } = getState();
  const { countryCode, languageCode } = pageData;
  const locale = { country: countryCode || '', lang: languageCode || '' };
  const sortParams = mapSortToApiRequest(sortDirection);
  const pageSize = itemsPerPage;

  const filterData = mapFilterData(pageData.type, pageData.data, pageData.categoryWrapped, dataFilter, filter);
  const filtersParams = mapFilterToUrl(filter, filterData);
 // console.log('filtersParams', filtersParams);
  return dispatch(acProductsListGet({
    ...locale, ...filtersParams, ...sortParams, size: pageSize, ...params,
  }, short));
};

type TProductsListFilterGet = (params: Partial<IGetProductsParams>) => any;
export const acProductsListFilterGet: TProductsListFilterGet = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getProductFilterUrlLocal(), {
      ...params,
      size: params.size || itemsPerPage,
    }),
    endpoint1: addParamsToUrl(getProductFilterUrl(), {
      ...params,
      size: params.size || itemsPerPage,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.PRODUCTS_LIST_GET_FILTER_REQUEST, actions.PRODUCTS_LIST_GET_FILTER_SUCCESS, actions.PRODUCTS_LIST_GET_FILTER_FAILED],
  },
});

export const acProductsListFilterGetAction = (params) => (dispatch, getState: () => IStore) => {
  const {
    pageData,
    productsList: { filter, dataFilter, sortDirection },
  } = getState();
  const { countryCode, languageCode } = pageData;
  const locale = { country: countryCode || '', lang: languageCode || '' };
  const sortParams = mapSortToApiRequest(sortDirection);
  const pageSize = itemsPerPage;

  const filterData = mapFilterData(pageData.type, pageData.data, pageData.categoryWrapped, dataFilter, filter);
  const filtersParams = mapFilterToUrl(filter, filterData);
  return dispatch(acProductsListFilterGet({
    ...locale, ...filtersParams, ...sortParams, size: pageSize, ...params,
  }));
};

export const mapQueryFilterParams = (query: IContextQuery): Partial<IProductListFilter> => {
  const { params, ...queryString } = query;

  const productsFilter: Partial<IProductListFilter> = {
    [EProductsListFilter.sexId]:  ([] as string[]),
    [EProductsListFilter.brandId]: ([] as number[]),
    [EProductsListFilter.categoryId]: ([] as string[]),
    [EProductsListFilter.categoryIdP]: ([] as string[]),
  };

  if (queryString[EProductsListFilter.sexId]) {
    if (Array.isArray(queryString[EProductsListFilter.sexId])) {
      productsFilter[EProductsListFilter.sexId] = (queryString?.[EProductsListFilter.sexId] as string[])?.map((sexId) => `${sexId}`);
    } else {
      productsFilter[EProductsListFilter.sexId] = [`${queryString[EProductsListFilter.sexId]}`];
    }
  }

  if (queryString[EProductsListFilter.categoryId]) {
    if (Array.isArray(queryString[EProductsListFilter.categoryId])) {
      queryString[EProductsListFilter.categoryId] = (queryString?.[EProductsListFilter.categoryId] as string[])?.map((catId) => `${catId}`);
    } else {
      queryString[EProductsListFilter.categoryId] = [`${queryString[EProductsListFilter.categoryId]}`];
    }
    const baseCategory = (queryString?.[EProductsListFilter.categoryId] as string[])?.filter((catId) => !catId.startsWith('p'));
    const popularCategory = (queryString?.[EProductsListFilter.categoryId] as string[])?.filter((catId) => catId.startsWith('p'));
    productsFilter[EProductsListFilter.categoryId] = baseCategory;
    productsFilter[EProductsListFilter.categoryIdP] = popularCategory;
  }

  if (queryString[EProductsListFilter.brandId]) {
    productsFilter[EProductsListFilter.brandId] = Array.isArray(queryString[EProductsListFilter.brandId])
      ? (queryString[EProductsListFilter.brandId] as string[])?.map((brandId) => Number(brandId))
      : [Number(queryString[EProductsListFilter.brandId])];
  }

  if (queryString[EProductsListFilter.sale]) {
    productsFilter[EProductsListFilter.sale] = queryString[EProductsListFilter.sale] === 'true';
  }
  if (queryString[EProductsListFilter.outlet]) {
    productsFilter[EProductsListFilter.outlet] = queryString[EProductsListFilter.outlet] === 'true';
  }
  if (queryString[EProductsListFilter.fresh]) {
    productsFilter[EProductsListFilter.fresh] = queryString[EProductsListFilter.fresh] === 'true';
  }
  if (queryString[EProductsListFilter.textSearch]) {
    productsFilter[EProductsListFilter.textSearch] = decodeURI(queryString[EProductsListFilter.textSearch]);
  }
  if (queryString[EProductsListFilter.campaignId]) {
    productsFilter[EProductsListFilter.campaignId] = queryString[EProductsListFilter.campaignId];
  }
  if (queryString[EProductsListFilter.sizes] !== undefined) {
    productsFilter[EProductsListFilter.sizes] = Array.isArray(queryString[EProductsListFilter.sizes])
      ? (queryString[EProductsListFilter.sizes] as string[])
      : ([queryString[EProductsListFilter.sizes]] as string[]);
  }
  if (queryString[EProductsListFilter.colorGroupId]) {
    productsFilter[EProductsListFilter.colorGroupId] = Array.isArray(queryString[EProductsListFilter.colorGroupId])
      ? queryString[EProductsListFilter.colorGroupId]
      : [queryString[EProductsListFilter.colorGroupId]];
  }
  if (queryString[EProductsListFilter.priceFrom]) {
    productsFilter[EProductsListFilter.priceFrom] = Array.isArray(queryString[EProductsListFilter.priceFrom])
      ? Number(queryString[EProductsListFilter.priceFrom][0])
      : Number(queryString[EProductsListFilter.priceFrom]);
  }
  if (queryString[EProductsListFilter.priceTo]) {
    productsFilter[EProductsListFilter.priceTo] = Array.isArray(queryString[EProductsListFilter.priceTo])
      ? Number(queryString[EProductsListFilter.priceTo][0])
      : Number(queryString[EProductsListFilter.priceTo]);
  }

  if (queryString[EProductsListFilter.fresh]) {
    productsFilter.fresh = true;
  }

  if (queryString[EProductsListFilter.pageSize]) {
    productsFilter[EProductsListFilter.pageSize] = Number(queryString[EProductsListFilter.pageSize]);
  }

  if (queryString[EProductsListFilter.utmSource]) {
    productsFilter[EProductsListFilter.utmSource] = queryString[EProductsListFilter.utmSource];
  }

  if (queryString[EProductsListFilter.utmMedium]) {
    productsFilter[EProductsListFilter.utmMedium] = queryString[EProductsListFilter.utmMedium];
  }

  if (queryString[EProductsListFilter.utmCampaign]) {
    productsFilter[EProductsListFilter.utmCampaign] = queryString[EProductsListFilter.utmCampaign];
  }

  if (queryString[EProductsListFilter.gclid]) {
    productsFilter[EProductsListFilter.gclid] = queryString[EProductsListFilter.gclid];
  }
  if (queryString[EProductsListFilter.trendingProducts]) {
    productsFilter[EProductsListFilter.trendingProducts] = queryString[EProductsListFilter.trendingProducts];
  }
  if (queryString[EProductsListFilter.trendingProductsPeriod]) {
    productsFilter[EProductsListFilter.trendingProductsPeriod] = queryString[EProductsListFilter.trendingProductsPeriod];
  }
  if (queryString[EProductsListFilter.fits]) {
    productsFilter[EProductsListFilter.fits] = Array.isArray(queryString[EProductsListFilter.fits]) ? queryString[EProductsListFilter.fits] : [queryString[EProductsListFilter.fits]];
  }
  if (queryString[EProductsListFilter.waists]) {
    productsFilter[EProductsListFilter.waists] = Array.isArray(queryString[EProductsListFilter.waists]) ? queryString[EProductsListFilter.waists] : [queryString[EProductsListFilter.waists]];
  }
  if (queryString[EProductsListFilter.ageId]) {
    productsFilter[EProductsListFilter.ageId] = Array.isArray(queryString[EProductsListFilter.ageId]) ? queryString[EProductsListFilter.ageId] : [queryString[EProductsListFilter.ageId]];
  }

  return productsFilter;
};

export const mapQueryToSortParams = (query: IContextQuery): Record<EProductsListSort.sort, string> => {
  const { params, ...queryString } = query;

  if (queryString[EProductsListSort.sort] && queryString[EProductsListSort.sortOrder]) {
    return {
      [EProductsListSort.sort]: queryString[EProductsListSort.sort] + ',' + queryString[EProductsListSort.sortOrder],
    };
  } else {
    return { [EProductsListSort.sort]: defaultSorting + ',desc' };
  }
};


type TGetCategoryId = (path: string[], categoryWrapped: IWrappedCategorySectionMapped[], querySexId: string | string[] | number, secondCase?: boolean) => null | string;

const getCategoryId: TGetCategoryId = (path, categoryWrapped, querySexId, secondCase = false) => {
  let result: null | string = null;
  const pathLength = path.length;
  let categorySlug: null | string = null;
  let brandOrSubCategorySlug: null | string = null;
  if (pathLength === 4) {
    categorySlug = path[3];
  }
  if (pathLength === 5) {
    categorySlug = path[3];
    brandOrSubCategorySlug = path[4];
    if (secondCase) {
      categorySlug = path[4];
      brandOrSubCategorySlug = null;
    }
  }
  if (pathLength > 5) {
    categorySlug = path[4];
    brandOrSubCategorySlug = path[5];
  }
  if (categorySlug) {
    const categoriesSection: IWrappedCategorySectionMapped | undefined = categoryWrapped.find(
      ({ sexId }) => Array.isArray(querySexId) ? querySexId.includes(`${sexId}`) : Number(sexId) === Number(querySexId),
    );
    const category = findCategoryBySlug(categorySlug, categoriesSection?.categories || []);
    if (category) {
      if (brandOrSubCategorySlug) {
        const subCategory = findCategoryBySlug(brandOrSubCategorySlug, category?.children || []);
        if (subCategory) {
          result = subCategory.categoryId;
        } else {
          result = category.categoryId;
        }
      } else {
        result = category.categoryId;
      }
    }
  }
  return !result && pathLength === 5 && !secondCase ? getCategoryId(path, categoryWrapped, querySexId, true) : result;
};

export const acProductsListInit = () => async (dispatch, getState: () => IStore) => {
  const {
    context: { query },
    pageData: { categoryWrapped, countryCode, languageCode, data, type: pageType },
  } = getState();
  const pages = data.pages || [];

  const {
    [EProductsListFilter.pageSize]: initPageSize,
    ...queryFilter
  }: Partial<IProductListFilter> = mapQueryFilterParams(query);
  const filterInit: Partial<IProductListFilter> = { ...queryFilter };

  if (pageType === PageTypes.fresh) {
    filterInit[EProductsListFilter.fresh] = true;
    filterInit[EProductsListFilter.sale] = false;
    filterInit[EProductsListFilter.outlet] = false;
  } else if (pageType === PageTypes.outlet) {
    filterInit[EProductsListFilter.fresh] = null;
    filterInit[EProductsListFilter.sale] = null;
    filterInit[EProductsListFilter.outlet] = true;
  }

  if (query?.sexId === undefined) {
    const sexId: string[] = !data?.filter?.sexId && pageType === PageTypes.kids ? [`${EGender.girl}`, `${EGender.boy}`] : (data?.filter?.sexId ? [`${Number(data?.filter?.sexId)}`] : []);
    query.sexId = sexId;
    if (sexId) {
      filterInit[EProductsListFilter.sexId] = sexId;
    }
  }

  if (Array.isArray(filterInit[EProductsListFilter.sexId])) {
    filterInit[EProductsListFilter.sexId] = (filterInit?.[EProductsListFilter.sexId] as string[])?.map((sexId) => `${sexId}`);
  } else if (!!filterInit[EProductsListFilter.sexId]) {
    filterInit[EProductsListFilter.sexId] = [`${filterInit[EProductsListFilter.sexId]}`];
  } else {
    filterInit[EProductsListFilter.sexId] = [];
  }

  if (!!query?.categoryId && !Array.isArray(query.categoryId)) {
    query.categoryId = [`${query.categoryId}`];
  }

  if (!!query?.brandId && !Array.isArray(query.brandId)) {
    query.brandId = [query?.brandId];
  }

  const currentSexIds = filterInit?.[EProductsListFilter.sexId] || [];
  // @ts-ignore
  const pathnameParts = [...(query.params || [])];
  const pathname = '/' + pathnameParts.join('/');
  const [ basePath ] = getBasePathAndSexSlug(pageType, pathname, pages);
  const brandCategoryPath = pathname.replace(basePath || pathname, '');
  const brandCategoryParts = brandCategoryPath.split('/');
  // Remove first empty part
  brandCategoryParts.shift();

  // Check if pathname contains category or brand slug
  if (!!brandCategoryParts?.length) {
    // Last slug should be category slug
     // OR Subcategory
    const categorySlug: string | undefined = brandCategoryParts.pop();
    let brandSlug: string | undefined;

    // Check if query contains categoryId
    if (query?.categoryId === undefined && categorySlug) {
      const categoryId = getCategoryId(pathnameParts, categoryWrapped, query.sexId);
      if (categoryId !== null) {
        query.categoryId = categoryId;
        filterInit[EProductsListFilter.categoryId] = [categoryId];
      }
      /*
      for (let i=0, c=categoryWrapped.length; i<c; i++) {
        const sexId = categoryWrapped[i].sexId;
        if (sexId && currentSexIds.includes(`${sexId}`)) {
          const sexIdCategories = categoryWrapped[i].categories;
          const slugCategory = findCategoryBySlug(categorySlug, sexIdCategories);
          if (!!slugCategory) {
            const categoryId = [`${slugCategory.categoryId}`];
            query.categoryId = categoryId;
            filterInit[EProductsListFilter.categoryId] = categoryId;
          }
        }
      }
       */
    }

    if (query?.categoryId === undefined) {
      // Last slug was not category slug
      brandSlug = categorySlug;
    } else {
      // First slug should be brand slug
      brandSlug = brandCategoryParts.shift();
    }

    if (query?.brandId === undefined && brandSlug) {
      const { payload: filterBrands } = await dispatch(
        acGetBrands({
          country: countryCode || 'EE',
          lang: languageCode || ELanguages.et,
          sexId: query.sexId as string,
        }),
      );

      const brand: IProductListFilterBrand | undefined = findBrandBySlug(brandSlug, filterBrands, currentSexIds);
      if (brand) {
        filterInit[EProductsListFilter.brandId] = [brand.brandId];
      }
    }
  }

  await dispatch(acChangeFilter(filterInit));

  if (query[EProductsListSort.sort] && query[EProductsListSort.sort] === ESortType.newFirst) {
    await dispatch(acChangeSorting(ESortType.newFirst));
  } else if (query[EProductsListSort.sort] && query[EProductsListSort.sort] === ESortType.sale) {
    await dispatch(acChangeSorting(query[EProductsListSort.sort]));
  } else if (query[EProductsListSort.sortOrder]) {
    await dispatch(acChangeSorting(query[EProductsListSort.sortOrder]));
  }

  await Promise.allSettled([
    dispatch(
      acProductsListGetAction({
        includeSizes: true,
        page: 0,
        size: initPageSize || itemsPerPage,
      }, true),
    ),
  ]);

  await dispatch(acProductsListSeo(filterInit[EProductsListFilter.brandId]));
  // Commenting out temporarily, I don't see that using it makes any difference
  // await dispatch(acSetPredefinedFilter()); // TODO not sure that we need it here, maybe we need move it to top or completely remove
  await dispatch(acFinishInit(true));
};

export const acProductsListSeo = (brandIdsInit?: number | number[]) => async (dispatch, getState: () => IStore) => {
  const {
    pageData: {
      data: { seo },
    },
    productsList: {
      dataFilter: { filterBrands, sexes },
      filter,
    },
  } = getState();

  const { brandId: brandIds } = filter;

  const ids = brandIdsInit !== undefined ? brandIdsInit : brandIds;
  const seoType = getSeoType(ids, filter);
  let seoInfo: ISeo | undefined;

  switch (seoType) {
    case 'brand':
      seoInfo = getBrandSeo(ids, filterBrands, seo);
      break;
    case 'sex':
      seoInfo = getSexSeo(sexes, seo);
      break;
    default:
      seoInfo = seo;
  }

  if (seoInfo) {
    dispatch(acProductsListSetDefaultSeoInfo(seoInfo));
  }
};

type SeoType = 'brand' | 'sex' | '-';
const getSeoType = (brandIds: number | number[], filter: IProductListFilter): SeoType => {
  if (brandIds !== undefined && Array.isArray(brandIds) && !!brandIds.length) {
    return 'brand';
  }

  const { categoryId, categoryIdP, outlet, sale, fresh, sexId } = filter;
  const kidsPage = sexId && Array.isArray(sexId) && sexId.sort().toString() === [EGender.boy, EGender.girl].sort().toString();

  if (!kidsPage && !outlet && !sale && !fresh && !categoryId?.length && !categoryIdP?.length) {
    return 'sex';
  }

  return '-';
};

const getSexSeo = (sexes, defaultSeo: ISeo | undefined): ISeo | undefined => {
  const selectedSex = sexes && sexes.find((attr) => attr.selected === true);
  const selectedSexAttributes = selectedSex ? selectedSex.seoAttributes : undefined;
  const sexSeoAttributes = !!selectedSexAttributes?.length ? selectedSexAttributes : (sexes && !!sexes.length ? sexes[0].seoAttributes : []);
  const sexSeo = !!sexSeoAttributes?.length ? sexSeoAttributes[0] : null;

  if (sexSeo) {
    return {
      ...defaultSeo,
      h1: sexSeo.seoH1Tag,
      title: `${sexSeo.seoH1Tag} | Denim Dream e-pood`,
    };
  }

  return defaultSeo;
};

const getBrandSeo = (brandIds: number | number[], filterBrands: IProductListFilterBrand[], defaultSeo: ISeo | undefined): ISeo | undefined => {
  let brandId: number | null = null;
  if (Array.isArray(brandIds) && brandIds.length === 1) {
    brandId = brandIds[0];
  } else if (!Array.isArray(brandIds)) {
    brandId = brandIds;
  }

  const findBrand = ({ brandId: bId }) => `${bId}` === `${brandId}`;
  const brand = !!filterBrands?.length && brandId !== null ? filterBrands.find(findBrand) : null;
  const seoAttributes = brand && brand.seoAttributes && brand.seoAttributes.length > 0 ? brand.seoAttributes[0] : null;

  if (!seoAttributes) {
    return defaultSeo;
  }

  return {
    description: seoAttributes.seoDescription || undefined,
    h1: seoAttributes.seoH1Tag || undefined,
    short_story: seoAttributes.seoDescription || undefined,
    title: seoAttributes.seoTitle || undefined,
  };
};

export const acProductsListSetDefaultSeoInfo = (seoInfo?: ISeo) => ({
  payload: seoInfo,
  type: actions.PRODUCTS_LIST_SET_DEFAULT_SEO_INFO,
});

export const acChangeFilter = (filter: Partial<IProductListFilter>): IProductsListFilterChange => ({
  payload: filter,
  type: actions.PRODUCTS_LIST_FILTER_CHANGE,
});

export const acClearFilter = (): IProductsListFilterClear => ({
  type: actions.PRODUCTS_LIST_FILTER_CLEAR,
});

export const acChangeFilterMobile = (filter: Partial<IProductListFilter>): IProductsListFilterMobileChange => ({
  payload: filter,
  type: actions.PRODUCTS_LIST_FILTER_MOBILE_CHANGE,
});

type TProductsMobileFilterListGet = (params: Partial<IGetProductsParams>) => any;
export const acProductsListMobileFilterGet: TProductsMobileFilterListGet = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getProductsListUrl(), {
      ...params,
      includeSizes: true,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST,
      actions.PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS,
      actions.PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED,
    ],
  },
});

export const acProductsListMobileFilterGetAction = () => (dispatch, getState: () => IStore) => {
  const {
    pageData,
    productsList: { dataFilter, filter, filterMobile, sortDirection },
  } = getState();
  const { countryCode, languageCode } = pageData;
  const locale = { country: countryCode || '', lang: languageCode || '' };
  const sortParams = mapSortToApiRequest(sortDirection);
  const pageSize = itemsPerPage;
  const filterData = mapFilterData(pageData.type, pageData.data, pageData.categoryWrapped, dataFilter, filter); // TODO filter or filterMobile ???
  const filtersMobileParams = mapFilterToUrl(filterMobile, filterData);

  return dispatch(acProductsListMobileFilterGet({
    ...locale,
    ...filtersMobileParams,
    ...sortParams,
    size: pageSize,
  }));
};

export const acFilterMobileApply = (): IProductsListMobileFilterApply => ({
  type: actions.PRODUCTS_LIST_FILTER_MOBILE_APPLY,
});

export const acFilterMobileCancel = (): IProductsListFilterCancel => ({
  type: actions.PRODUCTS_LIST_FILTER_MOBILE_CANCEL,
});

export const acFilterMobileClear = (): IProductsListFilterMobileClear => ({
  type: actions.PRODUCTS_LIST_FILTER_MOBILE_CLEAR,
});

export const acChangeSorting = (sortDirection: ESortType): IProductsListSortChange => ({
  payload: sortDirection,
  type: actions.PRODUCTS_LIST_SORT_CHANGE,
});

export const acFinishInit = (state): IProductsListFinishInit => ({
  payload: state,
  type: actions.PRODUCTS_LIST_FINISH_INIT,
});

export const acSetPredefinedFilter = () => (dispatch, getState: () => IStore) => {
  const {
    pageData: {
      categoryWrapped,
      data: { filter: pageDataFilter },
      type: pageDataType,
    },
    productsList: { filter },
  } = getState();

  const pageSexId = getPageSexId(getState());
  const categoryWrappedSexId =
    (pageDataFilter?.sexId && categoryWrapped.find((category) => category.sexId === pageDataFilter.sexId)) || null;
  const categories = categoryWrappedSexId?.categories;
  if (pageDataFilter && categories) {
    const params = setPredefinedFilter(pageDataFilter, categories, filter, pageDataType, pageSexId);
    if (params) {
      return dispatch(acChangeFilter(params));
    }
  }
};
