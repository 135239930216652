import { GetServerSidePropsContext } from 'next';
import { parseCookies } from 'nookies';
import parser from 'ua-parser-js';

import { acSetRecentlyViewedState } from '@actions/acCommon';
import { acConfirmCustomerSignupEmail } from '@actions/acLoyaltyProgram';
import { acRouteChangeState } from '@actions/acNavigation';
import { acGetTranslationDefault } from '@actions/acTranslations';
import { miniSite, mobileMode, mobileSession } from '@constants/config';
import { anyProductWasViewed } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import { cartPaymentConfirm, cartStatusPaymentFailed, homePage } from '@constants/routes';
import { passwordResetKey } from '@constants/urls';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage } from '@interfaces/page/store';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { acPageNativeSettingsSet } from '@reducers/nativeSettings/actions';
import { getNativeSettingsFromBrowserStorage } from '@reducers/nativeSettings/browserStorage';
import { getCountryAndLanguageCode, getFirstRun } from '@utils/common';
import { gaIdCookieName, KEY_COUNTRY_LANGUAGE, removeBonuses } from '@utils/cookies';
import { getListSize } from '@utils/listSize';
import { isProductGiftCard } from '@utils/product';
import { replaceRoute } from '@utils/routes';
import { scrollToTopQuick } from '@utils/scroll';
import { getSessionId } from '@utils/sessionId';
import { getToken } from '@utils/token';
import { makeRedisRequest } from '../services/SSRService/Cache';

import { IDDReduxStore } from '../store/withRedux';
import { GetServerSidePropsCallback } from '../store/wrapper';
import { initAccountConfirmPages, initAccountPages } from './acAccount';
import {
  acAuthSetGaId,
  acAuthSetSessionId,
  acAuthSetToken,
  acCustomerGetProfile,
  acGetSocialApps,
  acLoginViaFBToken,
  acResetPasswordCheck,
} from './acAuth';
import {
  acCartInit, acCartInitFailed, acCartInitPaymentConfirm, acOrderConfirmedInit,
} from './acCart';
import { acContactsInit } from './acContacts';
import { onSaveContextQuery, onSaveDeviceType, onSaveHost } from './acContext';
import { acGiftCardInit } from './acGiftCard';
import { acInitLookBookDetails } from './acLookBook';
import {
  acFirstRunState, acPageChangeListSizeAction, acPageCountrySelectAction, acPageMobileUpdate, acPageSessionState,
  acPageSideMenuPageAction, acPageSideMenuStateAction,
} from './acPage';
import {
  acSaveSiteSettings,
  onGetAllTranslationsAction,
  onGetCategoryWrappedAction,
  onGetNavBar,
  onInitPageData,
  onPageDataSetLanguageAction,
  onPageDataSetLocaleAction,
  onUpdatePageData,
} from './acPageData';
import { acProductDetailsInit } from './acProductDetails';
import { acProductsListInit } from './acProductsList';
import { acStoresInit } from './acStores';
import { acWishListInit } from './acWishList';

const dispatchArray = async (actions, dispatch) => {
  if (actions.length > 0) {
    await Promise.allSettled(actions.map((action) => dispatch(action)));
  }
};

export const initUser = (context?) => async (dispatch, getState: () => IStore) => {
  const pageData: IPageDataReducer = getState().pageData;
  const { context: contextStore } = getState();
  const countryCode = pageData?.countryCode || 'EE';

  const sessionId = getSessionId(countryCode, context);
  if (sessionId) {
    await dispatch(acAuthSetSessionId(sessionId));
  } else {
    // await dispatch(acAuthCreateSessionAction(context));
  }
  const tokenStored = getToken(context);
  if (!mobileSession || (contextStore?.query?.params?.length ?? 0) > 1) {
    if (tokenStored) {
      const token = JSON.parse(tokenStored);
      await dispatch(acAuthSetToken(token));
      await dispatch(acCustomerGetProfile());
    } else if (contextStore?.query?.hasOwnProperty('fbToken') && contextStore.query.fbToken) {
      await dispatch(acLoginViaFBToken(contextStore.query.fbToken));
    }
  }
};


export const initPageBase = (context?) => async (dispatch, getState: () => IStore) => {
  const { context: { query: { params: paramsStore } }, pageData } = getState();
  const pageType = pageData?.type;
  const countryCode = pageData?.countryCode || 'EE';
  const languageCode = pageData?.languageCode || 'et';

  const params = paramsStore || context?.query?.params;
  switch (pageType) {
    case PageTypes.account:
    case PageTypes.account_changePassword:
    case PageTypes.account_details:
    case PageTypes.account_delivery:
    case PageTypes.account_help:
    case PageTypes.account_linkedAccounts:
    case PageTypes.account_payment:
    case PageTypes.account_notifications:
      await dispatch(initAccountPages(pageType));
      break;
    // FR-666
    // case PageTypes.home:
    // await dispatch(acInitHomePage());
    // break;
    case PageTypes.account_confirm:
      await dispatch(initAccountConfirmPages());
      break;
    case PageTypes.order_confirmed:
      await dispatch(acOrderConfirmedInit());
      break;
    case PageTypes.giftCard:
      await dispatch(acGiftCardInit(params));
      break;
    case PageTypes.stores:
      await dispatch(acStoresInit());
      break;
    case PageTypes.fresh:
    case PageTypes.outlet:
    case PageTypes.kids:
    case PageTypes.productsList:
      await dispatch(acProductsListInit());
      break;
    case PageTypes.productDetail:
      await dispatch(acProductDetailsInit());
      const { pageData: { data: { pages } }, productDetails: { productDetails } }: IStore = getState();
      if (!productDetails) {
        return {
          props: {
            initialReduxState: getState(),
          },
          redirect: {
            destination: homePage(countryCode, languageCode),
            statusCode: 302,
          },
        };
      } else {
        const isGiftCard = isProductGiftCard(productDetails);
        if (isGiftCard) {
          const giftCardPage = pages?.find((pageItem) => pageItem.type === PageTypes.giftCard);
          if (giftCardPage) {
            return {
              props: {
                initialReduxState: getState(),
              },
              redirect: {
                destination: '/' + giftCardPage.url,
                statusCode: 301,
              },
            };
          }
        }
      }
      break;
    case PageTypes.contacts:
      await dispatch(acContactsInit());
      break;
    case PageTypes.wishlist:
      await dispatch(acWishListInit());
      break;
    case PageTypes.lookbookDetail:
      await dispatch(acInitLookBookDetails());
      break;
    case PageTypes.cart:
      if (params && params.length > 3) {
        if (params[3] === cartStatusPaymentFailed) {
          const trId = params[4];
          await dispatch(acCartInitFailed(trId));
        } else if (params[3] === cartPaymentConfirm) {
          removeBonuses(context, countryCode);
          await dispatch(acCartInitPaymentConfirm());
        }
      } else {
        await dispatch(acCartInit(context));
      }
      break;
    default:
      break;
  }
};

const checkFirstRun = (context) => (dispatch) => {
  const isFirstRun = getFirstRun(context);
  if (!isFirstRun) {
    return dispatch(acFirstRunState(true));
  }
};

export const getServerSidePropsBase: GetServerSidePropsCallback<IDDReduxStore, any> = (reduxStore) => async (context: GetServerSidePropsContext) => {
  // Object.freeze(context);
  const dispatch: TThunkDispatch = reduxStore.dispatch;
  const timeStart = Date.now();
  const cookies = parseCookies(context);
  const langInCookie = cookies[KEY_COUNTRY_LANGUAGE]?.split('/');
  const isIndexPage = context?.resolvedUrl === '/';
  const isNeedRedirectToCountry = isIndexPage && langInCookie?.length > 1;
  if(miniSite && isIndexPage) {
    return {
      props: {
        initialState: reduxStore.getState(),
      },
      redirect: {
        destination: homePage('EE', 'et'),
        statusCode: 302,
      },
    };
  }
  else if (isNeedRedirectToCountry && !mobileMode) {
    if (langInCookie.length > 2) {
      langInCookie.shift();
    }
    const countryCode = langInCookie[0];
    const languageCode = langInCookie[1];

    return {
      props: {
        initialState: reduxStore.getState(),
      },
      redirect: {
        destination: homePage(countryCode, languageCode),
        statusCode: 302,
      },
    };
  } else if (isIndexPage) {
    dispatch(acPageCountrySelectAction(true));
  }
  let initFromBrowserStorageResult = { performance: { dataFromBrowserStorage: -1 } };
  if (!mobileSession) {
    dispatch(checkFirstRun(context));
    initFromBrowserStorageResult = await dispatch(preInitDataFromBrowserStorage(context));
  }
  const firstStepResult = await dispatch(preInitData(context));
  const timeFirstStep = Date.now();
  await dispatch(onInitPageData(context));

  const timeInitDataStep = Date.now();
  if (!mobileSession) {
    await dispatch(initUser(context));
  }
  const timeInitUser = Date.now();
  let pageTypeInit: any = null;
  if (!mobileSession) {
    pageTypeInit = await dispatch(initPageBase(context));
  }
  const secondStepTime = Date.now();

  const resultState: IStore = reduxStore.getState();

  if (pageTypeInit?.redirect) {
    return {
      props: {
        initialState: resultState,
      },
      redirect: pageTypeInit?.redirect,
    };
  }
  if (isNeedRedirectToCountry && mobileMode) {
    resultState.pageData.type = PageTypes.loading;
  }

  return {
    props: {
      initialState: {
        ...resultState,
        page: { ...resultState.page, sessionLoading: mobileSession },
        performance: {
          ...resultState.performance,
          ...initFromBrowserStorageResult.performance,
          getServerSideProps: {
            firstStep: (timeFirstStep - timeStart) / 1000,
            firstStepDetails: {
              ...firstStepResult.performance,
            },
            getApiData: (Date.now() - secondStepTime) / 1000,
            getCustomerData: (timeInitUser - timeInitDataStep) / 1000,
            getPageData: (timeInitDataStep - timeFirstStep) / 1000,
            initPageBase: (secondStepTime - timeInitUser) / 1000,
            startTime: timeStart,
            total: (Date.now() - timeStart) / 1000,
          },
        },
      },
    },
  };
};

const preInitDataFromBrowserStorage = (context: GetServerSidePropsContext | null) => async (dispatch): Promise<{ performance: {
    dataFromBrowserStorage: number;
  }}> => {
  const startFn = Date.now();
  const dispatchActions: any[] = [];

  const nativeSettingsData = getNativeSettingsFromBrowserStorage(context);
  if (nativeSettingsData && Object.keys(nativeSettingsData).length > 0) {
    dispatchActions.push(acPageNativeSettingsSet(nativeSettingsData));
  }

  const listSize: boolean = getListSize(context);
  dispatchActions.push(acPageChangeListSizeAction(listSize));

  await dispatchArray(dispatchActions, dispatch);
  const finishFn = Date.now();
  return Promise.resolve({
    performance: {
      dataFromBrowserStorage: (finishFn - startFn) / 1000,
    },
  });
};

const preInitData = (context: GetServerSidePropsContext) => async (dispatch) => {
  const siteSettings = (await makeRedisRequest('site-settings', false).then((res) => res && JSON.parse(res ?? null)).catch(() => null)) || { disableReactCache: false };
  await dispatch(acSaveSiteSettings(siteSettings));

  const firstStep: any[] = [];
  const device = JSON.parse(JSON.stringify(parser(context?.req?.headers['user-agent'])) ?? null);

  firstStep.push(onSaveDeviceType(device));
  const host = context?.req?.headers.host || undefined;
  if (host) {
    firstStep.push(onSaveHost(host));
  }

  const cookies = parseCookies(context);
  if (cookies[gaIdCookieName]) {
    const gaId = cookies[gaIdCookieName].replace('GA1.1.', '').replace('GA1.2.', '');
    firstStep.push(acAuthSetGaId(gaId));
  }
  if (cookies[anyProductWasViewed]) {
    firstStep.push(acSetRecentlyViewedState());
  }

  let countyCode: string | null = null;
  let langCode: string | null = null;

  if (context?.query?.params && Array.isArray(context.query?.params) && context?.query?.params?.length > 1) {
    countyCode = context.query?.params[0];
    langCode = context.query?.params[1];
  } else {
    const langInCookie = cookies[KEY_COUNTRY_LANGUAGE]?.split('/');
    if (langInCookie) {
      if (langInCookie.length > 2) {
        langInCookie.shift();
      }
      countyCode = langInCookie[0];
      langCode = langInCookie[1];
    }
  }
  let startCw = 0;
  let finishCw = 0;
  if (countyCode && langCode) {
    firstStep.push(onPageDataSetLocaleAction(countyCode, langCode));
    startCw = Date.now();
    await dispatch(onGetCategoryWrappedAction({ country: countyCode, lang: langCode }));
    if(mobileMode){
      firstStep.push(onGetNavBar(langCode));
    }
    finishCw = Date.now();
  }
  firstStep.push(onSaveContextQuery(context.query));
  firstStep.push(acGetTranslationDefault());
  firstStep.push(acGetSocialApps());

  if (context.query && context.query.type && context.query.type === passwordResetKey) {

    if (typeof context.query.token === 'string') {
      await dispatch(acResetPasswordCheck(context.query.token)).then((response) => {
        if (response.result) {
          if (response.payload.data.attributes.exists) {
            firstStep.push(acPageSideMenuPageAction(ESideMenuPage.newPassword));
            firstStep.push(acPageSideMenuStateAction(true));
          } else {
            firstStep.push(acPageSideMenuPageAction(ESideMenuPage.newPasswordExpired));
            firstStep.push(acPageSideMenuStateAction(true));
          }
        }
      });
    }
  }

  await dispatchArray(firstStep, dispatch);

  return Promise.resolve({
    performance: {
      categoryWrapped: (finishCw - startCw) / 1000,
    },
  });
};

const preInitDataClientSide = () => async (dispatch, getState: () => IStore) => {
  const { context: { query } } = getState();
  let countyCode: string | null = query?.params ? query?.params[0] ?? null : null;
  let langCode: string | null = query?.params ? query?.params[1] ?? null : null;

  if (!countyCode || !langCode) {
    const countryAndLanguageCode = getCountryAndLanguageCode(null);
    if (countryAndLanguageCode) {
      countyCode = countryAndLanguageCode.countryCode || null;
      langCode = countryAndLanguageCode.languageCode || null;
    }
    if (countyCode && langCode) {
      dispatch(onPageDataSetLocaleAction(countyCode, langCode));
      await dispatch(onGetCategoryWrappedAction({ country: countyCode, lang: langCode }));
    }
  }
  const listSize: boolean = getListSize(null);
  dispatch(acPageChangeListSizeAction(listSize));
  return Promise.resolve(true);
};

export const updateMobilePage = (context: Partial<GetServerSidePropsContext>) => async (dispatch, getState: () => IStore) => {
  const { navigation: { replacingRoute, beforePopState, beforePopStateHistory } } = getState();
  if (!replacingRoute) {
    await dispatch(acPageMobileUpdate(true));
    if (!beforePopState || !beforePopStateHistory?.__scrollY) {
      scrollToTopQuick();
    }
    await dispatch(onSaveContextQuery(context.query || {}));
    await dispatch(onUpdatePageData(context?.query?.params as string[] || []));
    await dispatch(initPageBase(context));
    await dispatch(acPageMobileUpdate(false));
  }
  await dispatch(acRouteChangeState(false));
};

export const updateWebPage = (context: Partial<GetServerSidePropsContext>) => async (dispatch, getState: () => IStore) => {
  await dispatch(onSaveContextQuery(context.query || {}));
  const { navigation: { replacingRoute } } = getState();
  if (!replacingRoute) {
    // scrollToTopQuick();
  }
  dispatch(acRouteChangeState(false));
};


export const getClientSideProps = () => async (dispatch) => {
  if (mobileSession) {
    dispatch(checkFirstRun(null));
    await dispatch(preInitDataFromBrowserStorage(null));
    await dispatch(preInitDataClientSide());
    await dispatch(onInitPageData(null));
    await dispatch(initUser(undefined));
    const initPageBaseResult = await dispatch(initPageBase(undefined));
    if (initPageBaseResult && initPageBaseResult.redirect && initPageBaseResult.redirect.destination) {
      replaceRoute(initPageBaseResult.redirect.destination, false);
    }
  }
  await dispatch(acPageSessionState(false));
};

export const getServerSidePropsLoyalty: GetServerSidePropsCallback<IDDReduxStore, any> = (reduxStore) => async (context: GetServerSidePropsContext) => {
  // Object.freeze(context);
  const dispatch: TThunkDispatch = reduxStore.dispatch;
  const timeStart = Date.now();
  await dispatch(onGetAllTranslationsAction());
  const firstStepResult = await dispatch(preInitData(context));
  const timeFirstStep = Date.now();
  const resultState: IStore = reduxStore.getState();
  return {
    props: {
      initialState: {
        ...resultState,
        page: { ...resultState.page, sessionLoading: mobileSession },
        performance: {
          ...resultState.performance,
          getServerSideProps: {
            firstStep: (timeFirstStep - timeStart) / 1000,
            firstStepDetails: {
              ...firstStepResult.performance,
            },
            startTime: timeStart,
            total: (Date.now() - timeStart) / 1000,
          },
        },
      },
    },
  };
};

export const getServerSidePropsLoyaltyConfirm: GetServerSidePropsCallback<IDDReduxStore, any> = (reduxStore) => async (context: GetServerSidePropsContext) => {
  const dispatch: TThunkDispatch = reduxStore.dispatch;
  await dispatch(onGetAllTranslationsAction());
  await dispatch(preInitLoyaltyData(context));
  await dispatch(initLoyaltyConfirm(context));
  const resultState: IStore = reduxStore.getState();
  return {
    props: {
      initialState: {
        ...resultState,
        page: { ...resultState.page, sessionLoading: mobileSession },
        performance: {
          ...resultState.performance,
        },
      },
    },
  };
};


const preInitLoyaltyData = (context: GetServerSidePropsContext) => async (dispatch) => {
  const siteSettings = (await makeRedisRequest('site-settings').then((res) => res && JSON.parse(res)).catch(() => null)) || { disableReactCache: false };
  await dispatch(acSaveSiteSettings(siteSettings));

  const firstStep: any[] = [];
  const device = JSON.parse(JSON.stringify(parser(context?.req?.headers['user-agent'])));

  firstStep.push(onSaveDeviceType(device));
  const host = context?.req?.headers.host || undefined;
  if (host) {
    firstStep.push(onSaveHost(host));
  }
  const cookies = parseCookies(context);
  if (cookies[gaIdCookieName]) {
    const gaId = cookies[gaIdCookieName].replace('GA1.1.', '').replace('GA1.2.', '');
    firstStep.push(acAuthSetGaId(gaId));
  }
  let countyCode: string | null = null;
  let langCode: string | null = null;
  const langInCookie = cookies[KEY_COUNTRY_LANGUAGE]?.split('/');
  if (langInCookie) {
    if (langInCookie.length > 2) {
      langInCookie.shift();
    }
    countyCode = langInCookie[0];
    langCode = langInCookie[1];
  } else {
    countyCode = 'EE';
    langCode = 'et';
  }

  if (countyCode && langCode) {
    firstStep.push(onPageDataSetLocaleAction(countyCode, langCode));
  }
  firstStep.push(onSaveContextQuery(context.query));

  await dispatchArray(firstStep, dispatch);
  return Promise.resolve({
    performance: {},
  });
};

const initLoyaltyConfirm = (context: GetServerSidePropsContext) => async (dispatch, getState) => {
  const state: IStore = getState();
  const { context: { query } } = state;
  const pid = query?.pid ?? null;
  if (pid) {
    await dispatch(acConfirmCustomerSignupEmail(pid)).then((result) => {
      if (result.payload.data.attributes) {
        const langCode = result.payload.data.attributes.languageCode;
        if (langCode) {
          dispatch(onPageDataSetLanguageAction(langCode));
        }
      }
    });
  }
};
