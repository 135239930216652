import { IResult } from 'ua-parser-js';

import * as actions from '@constants/actions/context';
import {
  IContextSaveDeviceTypeAction,
  IContextSaveHostTypeAction,
  IContextSaveQueryAction,
} from '@interfaces/context/actions';
import { IContextQuery } from '@interfaces/context/reducer';

export const onSaveContextQuery: (query: IContextQuery) => IContextSaveQueryAction = (query) => ({
  payload: query,
  type: actions.CONTEXT_SAVE_QUERY,
});

export const onSaveDeviceType: (deviceType: IResult) => IContextSaveDeviceTypeAction = (deviceType) => ({
  payload: deviceType,
  type: actions.CONTEXT_SAVE_DEVICE,
});

export const onSaveHost: (host: string) => IContextSaveHostTypeAction = (host) => ({
  payload: host,
  type: actions.CONTEXT_SAVE_HOST,
});

